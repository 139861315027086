import React from "react";
import highQuality from "./images/high-quality.png";
import lowQuality from "./images/low-quality.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "./CustomForm";

export default function App() {
  return (
    <div>
      <div className="h-12 p-4">
        <h1 className="font-raleway text-2xl font-medium text-cyan-900">
          leafl.it
        </h1>
      </div>
      <div className="flex flex-col h-72 md:h-screen justify-end items-center">
        <div className="flex flex-col md:justify-end items-center w-2/3 absolute">
          <h1 className="font-montserrat text-4xl md:text-6xl font-bold text-cyan-900 md:w-2/3 text-center">
            Your <span className="text-orange-300">GPT-3</span> Study Buddy
          </h1>
          <img
            className="w-full md:w-5/6 low-quality"
            src={lowQuality}
            alt="high quality"
            loading="lazy"
            onLoad={() => {
              document.querySelector(".low-quality").src = highQuality;
            }}
          />
        </div>
        <div className="h-16 w-screen bg-orange-300"></div>
      </div>
      <div className="h-full p-3 md:p-0 md:h-screen w-screen bg-orange-300 flex flex-col justify-between items-center">
        <div className="h-5/6 w-full flex flex-col justify-center items-center">
          <div className=" w-11/12 md:w-1/2 bg-white drop-shadow-md rounded-md flex flex-col p-6 gap-5 justify-between">
            <div>
              <h1 className="font-montserrat text-3xl md:text-4xl font-bold text-cyan-900">
                Join the waitlist!
              </h1>
              <p className="font-montserrat text-md md:text-lg text-gray-600">
                We aren't quit ready yet... That's on us, but when we open our
                beta we'll make sure you get access.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center w-full">
              <div className="mc__form-container w-full md:w-5/6">
                <MailchimpSubscribe
                  url={
                    "https://email.us18.list-manage.com/subscribe/post?u=a1ee68bccfb73aac7ea6bb45d&id=7a5a076dac"
                  }
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
            <p className="font-montserrat text-sm md:text-md text-gray-600 pt-4">
              The only 3rd party we'll share your email with is{" "}
              <a className="underline" href="https://mailchimp.com/">
                Mailchimp
              </a>{" "}
              (kinda necessary).
            </p>
          </div>
        </div>
        <h5 className="font-montserrat mt-48 text-center text-md text-cyan-900 pb-6">
          Currently in development. Contact{" "}
          <a className="underline" href={"mailto:info@leafl.it"}>
            {"info@leafl.it"}
          </a>{" "}
          for more information.
        </h5>
      </div>
    </div>
  );
}
