import React, { useState } from "react";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email && onValidated({ EMAIL: email });
  };

  return (
    <form onSubmit={handleSubmit}>
      {status === "sending" && (
        <div className="font-montserrat text-xs md:text-sm text-neutral-300">
          sending...
        </div>
      )}
      {status === "error" && (
        <div
          className="font-montserrat text-xs md:text-sm text-red-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="font-montserrat text-xs md:text-sm text-emerald-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="flex flex-row gap-2 drop-shadow-md w-full h-16 bg-neutral-50 rounded-md p-3">
        <input
          placeholder="student@leafl.it"
          className="w-full bg-transparent !outline-none"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isRequired
        />
        <button
          type="submit"
          onClick={handleSubmit}
          className="w-1/2 bg-orange-300 rounded-md text-sm md:text-md text-gray-600"
        >
          Join Waitlist
        </button>
      </div>
    </form>
  );
};

export default CustomForm;
